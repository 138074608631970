<template>
  <main>
    <div>
      <div
        v-if="!loading && renderComponent"
        class="page-wrapper-shopify is-full-screen"
      >
        <!-- ======================== Order List Table ============================= -->
        <div class="container fluid">
          <v-row v-if="showMessage">
            <PBanner status="success" v-if="successMessage.length">
              {{ successMessage }}
            </PBanner>
            <PBanner status="warning" v-if="errorMessage.length">
              {{ errorMessage }}
            </PBanner>
          </v-row>
          <div class="Polaris-Card py-6">
            <div class="Polaris-Card__Section">
              <div class="Polaris-TextContainer">
                <h2 class="Polaris-Heading">Shipping from</h2>
                <p class="Polaris-TextField">
                  Define all destinations you send products to and shipping
                  costs based on that
                </p>
              </div>
            </div>
            <div class="Polaris-FormLayout px-6">
              <v-row class="d-flex align-end px-6">
                <v-col sm="12" md="4" class="pa-0">
                  Country
                  <PSelect
                    style="
                      border: 0.1rem solid rgb(201, 204, 207) !important;
                      border-radius: 4px !important;
                    "
                    :placeholder="
                      shippingDetailsCopy.country || 'Select Country'
                    "
                    :options="listOfCountries"
                    @change="SelectCountryAndGetCitiesList($event)"
                  />
                </v-col>
                <v-col sm="12" md="4" class="py-0 px-6">
                  City
                  <PSelect
                    style="
                      border: 0.1rem solid rgb(201, 204, 207) !important;
                      border-radius: 4px !important;
                    "
                    :placeholder="shipToCity || 'Select City'"
                    :options="listOfCitiesToShipFrom"
                    @change="SetShipFromCity($event)"
                  />
                </v-col>
                <v-col sm="12" md="4" class="pa-0">
                  Prcessing time
                  <PTextField
                    :placeholder="`ex: ${shippingDetailsCopy.proccessing_time} `"
                    v-model="shippingDetailsCopy.proccessing_time"
                    id="shipping_from_prcessing_time"
                    suffix="day"
                    type="number"
                  />
                </v-col>
              </v-row>
            </div>
            <div class="Polaris-Card__Section mt-6">
              <div class="Polaris-TextContainer">
                <h2 class="Polaris-Heading">Shipping To</h2>
              </div>
            </div>
            <div class="Polaris-FormLayout px-6">
              <div class="shipping_to_static pl-6">
                <v-row class="align-end">
                  <v-col sm="12" md="4" class="pa-0" style="width: 32.7%">
                    Country
                    <PSelect
                      style="
                        border: 0.1rem solid rgb(201, 204, 207) !important;
                        border-radius: 4px !important;
                      "
                      placeholder="Domestic"
                      :options="listOfCountries"
                      @change="
                        SetShipToCountry($event, shippingDetailsCopy.lines[0])
                      "
                      disabled
                    />
                  </v-col>
                  <v-col sm="12" md="4" class="py-0 px-6" style="width: 32.7%">
                    Shipping time
                    <PSelect
                      style="
                        border: 0.1rem solid rgb(201, 204, 207) !important;
                        border-radius: 4px !important;
                      "
                      :placeholder="
                        shippingDetailsCopy.lines[0].shipping_time ||
                        'Select Shipping time'
                      "
                      v-model="shippingDetailsCopy.lines[0].delivery_days"
                      :options="[
                        { label: '1 - 3 days', value: '1 - 3 days' },
                        { label: '3 - 7 days', value: '3 - 7 days' },
                        { label: '7 - 14 days', value: '7 - 14 days' },
                      ]"
                    />
                  </v-col>
                  <v-col
                    sm="6"
                    md="2"
                    class="pa-0 pr-6"
                    style="width: 12.7%; padding-left: 13px"
                  >
                    Currency
                    <PSelect
                      style="
                        border: 0.1rem solid rgb(201, 204, 207) !important;
                        border-radius: 4px !important;
                      "
                      :placeholder="
                        shippingDetailsCopy.lines[0].currency ||
                        'Select currency'
                      "
                      :options="currency"
                      v-model="shippingDetailsCopy.lines[0].currency"
                    />
                  </v-col>
                  <v-col
                    sm="6"
                    md="2"
                    class="pa-0"
                    style="width: 20%; padding-left: 17px"
                  >
                    Shipping cost
                    <PTextField
                      v-model="shippingDetailsCopy.lines[0].price"
                      id="shipping_to_cost"
                      type="number"
                    />
                  </v-col>
                </v-row>
              </div>
              <div
                class="shipping_to_dynamic pl-6 pt-6"
                v-if="
                  shippingDetailsCopy.lines &&
                  shippingDetailsCopy.lines.length > 1
                "
              >
                <ShippingTo
                  v-for="(line, index) in shippingDetailsCopy.lines.slice(1)"
                  :key="index"
                  :shippingTo="line"
                  :countries="countries"
                  @removeshipto="RemoveShipTo(line)"
                  @shippingtochange="ChangeShipTo($event, index + 1)"
                ></ShippingTo>
              </div>
              <div>
                <div class="mt-8 px-6">
                  <button
                    class="Polaris-Button Polaris-Button--plain"
                    type="button"
                    @click="AddShippingTo()"
                  >
                    <span class="Polaris-Button__Content"
                      ><span class="Polaris-Button__Text"
                        >Add a new destination</span
                      ></span
                    >
                  </button>
                </div>
                <div class="mt-8 px-6">
                  <button
                    class="Polaris-Button Polaris-Button--primary Polaris-Button--sizeLarge mr-6"
                    type="button"
                    @click="SaveChanges"
                    :loading="isSavingChanges"
                  >
                    <span class="Polaris-Button__Content"
                      ><span class="Polaris-Button__Text"
                        >Save changes</span
                      ></span
                    >
                  </button>
                  <button
                    class="Polaris-Button Polaris-Button--sizeLarge"
                    @click="DiscardChanges"
                    :disabled="!valueChanged"
                    type="button"
                  >
                    <span class="Polaris-Button__Content"
                      ><span class="Polaris-Button__Text"
                        >Discard changes</span
                      ></span
                    >
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="page-wrapper-shopify is-full-screen" v-else>
        <div class="container fluid">
          <PSkeletonPage fullWidth>
            <PLayout>
              <PLayoutSection>
                <PCard sectioned=""><PSkeletonBodyText /></PCard>
                <PCard sectioned="">
                  <PTextContainer>
                    <PSkeletonDisplayText size="small" />
                    <PSkeletonBodyText />
                  </PTextContainer>
                </PCard>
                <PCard sectioned="">
                  <PTextContainer>
                    <PSkeletonDisplayText size="small" />
                    <PSkeletonBodyText />
                  </PTextContainer>
                </PCard>
              </PLayoutSection>
            </PLayout>
          </PSkeletonPage>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
// services
import CommonData from "@/services/CommonData";
import SupplierShipping from "@/services/SupplierShipping";
// Components

import ShippingTo from "@/components/supplier/ShippingTo.vue";
// helpers
import { currency } from "@/utils/currency";

export default {
  components: { ShippingTo },
  data() {
    return {
      loading: true,
      countries: [],
      shippingFromCities: [],
      shippingToCities: [],
      shipToCity: "",
      shipping_to_length: [],
      currency: currency,
      shipping_from_country: {},
      shipping_from_city: {},
      shippingDetails: [],
      shippingDetailsCopy: [],
      showMessage: false,
      successMessage: "",
      errorMessage: "",
      renderComponent: true,
      isSavingChanges: false,
    };
  },
  async created() {
    await this.GetShippingsDetail();
  },
  computed: {
    listOfCountries() {
      // array of object with value and name keys from this.countries
      const listOfCountries = this.countries.map((country) => {
        return { label: country.name, value: country.id };
      });
      return listOfCountries;
    },
    listOfCitiesToShipFrom() {
      // array of object with value and name keys from this.countries
      const listOfCitiesToShip = this.shippingFromCities.map((city) => {
        return { label: city.name, value: city.id };
      });
      return listOfCitiesToShip;
    },
    listOfShippingTo() {
      // array of object with value and name keys from this.countries
      return this.shippingDetailsCopy.lines.slice(1);
    },
    valueChanged() {
      return (
        JSON.stringify(this.shippingDetails) !==
        JSON.stringify(this.shippingDetailsCopy)
      );
    },
  },

  methods: {
    async GetCountries() {
      try {
        const response = await CommonData.getCountries();
        this.countries = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async GetShippingsDetail() {
      try {
        const response = await SupplierShipping.getShippingsDetail();
        this.shippingDetails = response.data;
        this.shippingDetailsCopy = JSON.parse(
          JSON.stringify(this.shippingDetails)
        );
        this.shipToCity = this.shippingDetailsCopy.city;
        await this.GetCountries();
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    async SelectCountryAndGetCitiesList(countryID) {
      try {
        const response = await CommonData.getCitiesByCountry(countryID);
        this.shippingFromCities = response.data;
        const countryFound = this.countries.find(
          (country) => country.id === countryID
        );
        this.shippingDetailsCopy.country = countryFound.name;
        this.shippingDetailsCopy.country_iso2 = countryFound.iso2;
        this.shippingDetailsCopy.lines[0].country = countryFound.name;
        this.shippingDetailsCopy.lines[0].countryISO2 = countryFound.iso2;
        this.shippingDetailsCopy.city = null;
        this.shipToCity = null;
      } catch (error) {
        console.log(error);
      }
    },
    AddShippingTo() {
      const lineslength = this.shippingDetailsCopy.lines.length;
      this.shippingDetailsCopy.lines.push({
        country: `Select country ${lineslength}`,
        countryISO2: "",
        currency: "Select currency",
        delivery_days: "Select duration",
        price: 0,
      });
    },
    RemoveShipTo(lineToRemove) {
      const found = this.shippingDetailsCopy.lines.find(
        (ship) => ship.country == lineToRemove.country
      );
      if (found)
        this.shippingDetailsCopy.lines = this.shippingDetailsCopy.lines.filter(
          (line) => {
            return line != found;
          }
        );
    },
    CountryNameFromCountryISO(countryISO) {
      if (countryISO) {
        const country = this.countries.find(
          (country) => country.iso2 === countryISO
        );

        return country.name;
      }
    },
    SetShipFromCity(cityID) {
      const cityFound = this.shippingFromCities.find(
        (city) => city.id === cityID
      );
      if (cityFound) {
        this.shippingDetailsCopy.city = cityFound.name;
      }
    },
    SetShipToCountry(countryID, previousCountry) {
      const selectedCountry = this.countries.find(
        (country) => country.id === countryID
      );
      if (selectedCountry) {
        this.shippingDetailsCopy.lines.map((line) => {
          if (
            line.countryISO2 === previousCountry.countryISO2 ||
            line.country === previousCountry.country
          ) {
            line.country = selectedCountry.name;
            line.countryISO2 = selectedCountry.iso2;
          }
        });
      }
    },
    ChangeShipTo(newLine, key) {
      this.shippingDetailsCopy.lines[key] = newLine;
    },
    async SaveChanges() {
      this.isSavingChanges = true;
      if (
        JSON.stringify(this.shippingDetails) !==
        JSON.stringify(this.shippingDetailsCopy)
      ) {
        const foundUnvalidedLine = this.shippingDetailsCopy.lines.find(
          (line) => {
            return (
              !line.country ||
              line.country.startsWith("Select country") ||
              line.countryISO2.length === 0 ||
              !line.currency ||
              line.currency.startsWith("Select currency") ||
              line.delivery_days.startsWith("Select duration")
            );
          }
        );
        if (foundUnvalidedLine) {
          this.errorMessage =
            "Please check your shipping destinations (shipping to) details";
          this.showMessage = true;
          window.scrollTo(0, 0);
          setTimeout(() => {
            this.showMessage = false;
            this.errorMessage = "";
          }, 3000);
          this.isSavingChanges = false;
          return;
        }
        if (!this.shippingDetailsCopy.city) {
          window.scrollTo(0, 0);

          this.errorMessage = "Please select a city to ship from";
          this.showMessage = true;
          setTimeout(() => {
            this.showMessage = false;
            this.errorMessage = "";
          }, 3000);
          this.isSavingChanges = false;
          return;
        } else if (
          !this.shippingDetailsCopy.country ||
          !this.shippingDetailsCopy.country_iso2
        ) {
          window.scrollTo(0, 0);
          this.errorMessage = "Please select a country to ship from";
          this.showMessage = true;
          setTimeout(() => {
            this.showMessage = false;
            this.errorMessage = "";
          }, 3000);
          this.isSavingChanges = false;
          return;
        }
        this.shippingDetailsCopy.lines[0].primary = true;
        // Here ae add to the shipping details two new fields:
        // 1. primary - boolean - if true, this is the primary shipping details meaning the default one
        // 2. min_delivery_days - int - we need this value for the retailer so we can show the correct min shipping time
        // 3. max_delivery_days - int - we need this value for the retailer so we can show the correct max shipping time
        for (let i = 0; i < this.shippingDetailsCopy.lines.length; i++) {
          const element = this.shippingDetailsCopy.lines[i];
          if (i === 0) element.primary = true;
          else element.primary = false;

          const deleviryDays = element.delivery_days.split(" ");
          
          element.min_delivery_days = Number(deleviryDays[0]);
          element.max_delivery_days = Number(deleviryDays[2]);
        }

        const response = await SupplierShipping.updateShippingsDetail(
          this.shippingDetailsCopy
        );
        if (response) {
          window.scrollTo(0, 0);
          this.successMessage = "Your changes have been saved";
          this.showMessage = true;
          setTimeout(() => {
            this.showMessage = false;
            this.successMessage = "";
          }, 3000);
          this.isSavingChanges = false;
        } else {
          window.scrollTo(0, 0);
          this.errorMessage = "Something went wrong";
          this.showMessage = true;
          setTimeout(() => {
            this.showMessage = false;
            this.errorMessage = "";
          }, 3000);
          this.isSavingChanges = false;
        }
      } else {
        window.scrollTo(0, 0);

        this.errorMessage =
          "No value changed. Please update your informations first.";
        this.showMessage = true;
        setTimeout(() => {
          this.showMessage = false;
          this.errorMessage = "";
        }, 3000);
        this.isSavingChanges = false;
      }
    },
    forceRerender() {
      // Removing my-component from the DOM
      this.renderComponent = false;

      this.$nextTick(() => {
        // Adding the component back in
        this.renderComponent = true;
      });
    },
    DiscardChanges() {
      if (
        JSON.stringify(this.shippingDetails) !==
        JSON.stringify(this.shippingDetailsCopy)
      ) {
        this.shippingDetailsCopy = JSON.parse(
          JSON.stringify(this.shippingDetails)
        );
        this.forceRerender();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.Polaris-Select {
  border: 0.1rem solid #c9cccf !important;
  border-radius: 4px !important;
}
</style>
